import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  title: FelaCSS;
}

const styles = (): IStyles => ({
  title: {
    fontDefault: 12,
    lineHeight: '1em',
    color: colors.space,
  },
});

export default styles;
