import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  grid: FelaCSS;
  codeWrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    fontDefault: 15,
    color: colors.space,
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '88px auto',
    columnGap: spacing[6],
    paddingTop: spacing[3],
  },

  codeWrapper: {
    width: 88,
    height: 88,
    padding: '3px',
    overflow: 'hidden',
    border: `1px ${colors.space15} solid`,
    borderRadius: 8,
  },
});

export default styles;
