import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';
import type { Market } from '@bridebook/toolbox/src/gazetteer';
import { A, Box, ImageX } from '@bridebook/ui';
import { getBadgesInfo } from 'lib/app/badges-info';
import componentStyles from './footer-app-badges.style';

interface IProps {
  market: Market;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const FooterAppBadges = ({ market, onClick }: IProps) => {
  const { t } = useTranslation('common');
  const styles = componentStyles();
  const { apple, google, code } = getBadgesInfo(market);

  return (
    <Box style={styles.wrapper}>
      {t('footer.getApp.subheading', 'Plan together: anytime, anywhere')}
      <Box style={styles.grid}>
        <Box style={styles.codeWrapper}>
          <ImageX src={code.img} w={80} h={80} fit="scale" />
        </Box>
        <Box flexDirection="column">
          <Box pb={2}>
            <A href={apple.url} target="_blank" onClick={onClick}>
              <Box w={120}>
                <ImageX src={apple.img} w={150} h={50} fit="scale" />
              </Box>
            </A>
          </Box>
          <A href={google.url} target="_blank" onClick={onClick}>
            <Box w={120}>
              <ImageX src={google.img} w={150} h={58} fit="scale" />
            </Box>
          </A>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterAppBadges;
