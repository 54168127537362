import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { isEmpty } from 'ramda';
import React, { FC, memo } from 'react';
import { env } from 'lib/env';
import { AlternateLink } from 'lib/types';
import { getRobotsNoIndexContent } from 'lib/utils/seo';

interface IProps {
  title?: string;
  description?: string | null;
  ogTitle?: string | null;
  ogDescription?: string | null;
  ogImage?: string | null;
  ogType?: string | null;
  ogUrl?: string | null;
  robots?: string | null;
  plainTitle?: boolean;
  plainOgTitle?: boolean;
  children?: React.ReactNode;
  canonicalUrlOverride?: string;
  isIndexable?: boolean;
  facetsParams?: string[];
  isMainLiveCDN: boolean;
  isArticle?: boolean;
  canonical: string;
  alternate: AlternateLink[];
  seoIndex?: boolean;
  isLoggedIn?: boolean;
}

const PageHead: FC<IProps> = ({
  title,
  description,
  canonical,
  alternate = [],
  ogTitle,
  ogDescription,
  ogImage,
  ogType,
  ogUrl,
  robots,
  children,
  plainTitle = false,
  plainOgTitle = false,
  isMainLiveCDN,
  isArticle = false,
  canonicalUrlOverride,
  isIndexable = true,
  seoIndex = true,
  facetsParams,
  isLoggedIn,
}) => {
  const { t } = useTranslation('common');
  const defaultMeta = {
    description: t('defaultMeta.description'),
    'og:description': t('defaultMeta.ogDescription'),
    'og:title': t('defaultMeta.ogTitle'),
    'og:image': 'https://images.bridebook.com/assets/bblogo_fb.jpg?auto=compress,format',
    'og:type': 'website',
  };

  const getTitle = (t: string | null | undefined, plain: boolean) => {
    if (plain) return t || defaultMeta['og:title'];
    if (t) return `${String(title)} | Bridebook`;
    return defaultMeta['og:title'];
  };

  const pageTitle = getTitle(title, plainTitle);
  const pageDescription = description || description === '' ? description : defaultMeta.description;
  const pageOgDescription = ogDescription || defaultMeta['og:description'];
  const pageOgImage = ogImage || defaultMeta['og:image'];
  const pageOgTitle = getTitle(ogTitle, plainOgTitle);
  const pageOgType = ogType || defaultMeta['og:type'];
  const pageOgUrl = ogUrl || canonical;

  const shouldNotIndex = (() => {
    if (!isMainLiveCDN || !isIndexable || !seoIndex) return true;
    if (isArticle) return false;
    return isLoggedIn;
  })();

  const canonicalUrl = canonicalUrlOverride || canonical;
  const themeColor = isLoggedIn ? '#4D52E3' : '#FFFFFF';

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      {shouldNotIndex && (
        <meta
          name="robots"
          content={getRobotsNoIndexContent(isMainLiveCDN, isEmpty(facetsParams))}
        />
      )}
      {!shouldNotIndex && env.LIVE && robots && <meta name="robots" content={robots} />}
      {!env.LIVE && (
        <meta
          property="debug:indexable"
          content={`indexable=${isIndexable};seoIndex=${seoIndex}`}
        />
      )}
      <meta property="og:title" content={pageOgTitle} />
      <meta property="og:description" content={pageOgDescription} />
      <meta property="og:type" content={pageOgType} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:image" content={pageOgImage} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {alternate.map(({ href, hrefLang }) => (
        <link rel="alternate" href={href} hrefLang={hrefLang} key={hrefLang} />
      ))}
      {pageOgUrl && <meta property="og:url" content={pageOgUrl} />}
      <meta property="og:site_name" content="Bridebook" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@bridebook" />
      <meta name="p:domain_verify" content="797eb482a41518e7a6dd4bd7f80c1297" />
      <meta name="theme-color" content={themeColor} />
      <meta name="referrer" content="always" />
      <meta
        content="viewport-fit=cover, width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        name="viewport"
      />

      {children}
    </Head>
  );
};

export default memo(PageHead);
