import { FelaCSSWithCustomSelectors } from '../fela/flowtypes';

type StyleTypes = {
  icon: FelaCSSWithCustomSelectors;
};

const styles = (): StyleTypes => ({
  icon: { flexShrink: 0, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' },
});

export default styles;
