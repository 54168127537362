import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconBurger = ({ id, color, width = 14, style, ...restProps }: IProps) => {
  const viewBoxWidth = 14;
  const viewBoxHeight = 11.6;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M0 1c0-.5.4-1 1-1h12c.5 0 1 .4 1 1 0 .5-.4 1-1 1H1c-.6-.1-1-.5-1-1zm0 4.8c0-.5.4-1 1-1h12c.5 0 1 .4 1 1 0 .5-.4 1-1 1H1c-.6-.1-1-.5-1-1zm0 4.8c0-.5.4-1 1-1h12c.5 0 1 .4 1 1 0 .5-.4 1-1 1H1c-.6 0-1-.5-1-1z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconBurger;
