import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierVenue = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7201 3C12.5657 3.00059 12.4149 3.05459 12.2868 3.1552C12.1586 3.25581 12.0588 3.39851 12 3.5653C11.9411 3.39838 11.8412 3.25559 11.7129 3.15497C11.5846 3.05435 11.4337 3.00042 11.2792 3C11.0647 3.02167 10.8652 3.1373 10.7207 3.32368C10.5762 3.51005 10.4974 3.75339 10.5001 4.00484C10.5001 5.10515 11.8 6 12 6C12.2 6 13.4999 5.10515 13.4999 4.00484C13.5026 3.75326 13.4237 3.50981 13.279 3.32341C13.1344 3.13701 12.9347 3.02147 12.7201 3V3Z"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 15.5L8.5 13.5V11.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 16.5V22.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.5L7.5 10.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.5L16.5 10.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 15.5L15.5 13.5V11.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 16.5V22.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 22.5H20.5"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.5C12.663 15.5 13.2989 15.7634 13.7678 16.2322C14.2366 16.7011 14.5 17.337 14.5 18V22.5H9.5V18C9.5 17.337 9.76339 16.7011 10.2322 16.2322C10.7011 15.7634 11.337 15.5 12 15.5Z"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierVenue;
