import { mergeDeepRight } from 'ramda';
import React, { FunctionComponent } from 'react';
import { IColSpacing } from '../../../themes/types';
import Box from '../../fela/Box';
import { FelaCSS } from '../../fela/flowtypes';
import componentStyles from './col.style';

type BoxProps = Parameters<typeof Box>[0];

interface IProps extends IColSpacing, BoxProps {
  style?: FelaCSS;
}

const Col: FunctionComponent<IProps> = ({
  children,
  style = {},
  xs = 12,
  sm = xs,
  md = sm,
  lg = md,
  xsStart = 0,
  smStart = xsStart,
  mdStart = smStart,
  lgStart = mdStart,
  gap,
  ...restProps
}) => {
  const styles = componentStyles({
    xs,
    sm,
    md,
    lg,
    xsStart,
    smStart,
    mdStart,
    lgStart,
    gap,
  });

  return (
    <Box style={mergeDeepRight(styles.wrap, style)} data-name="col" {...restProps}>
      {children}
    </Box>
  );
};

export default Col;
