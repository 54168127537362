import { mergeDeepRight } from 'ramda';
import React, { PropsWithChildren } from 'react';
import { IStylingProps, TGridSteps, TLayout } from '../../../themes/types';
import Box from '../../fela/Box';
import { FelaCSS } from '../../fela/flowtypes';
import componentStyles from './row.style';

interface IProps extends IStylingProps, PropsWithChildren {
  style?: FelaCSS;
  gap?: TLayout;
  rowGap?: TLayout;
  columnGap?: TLayout;
  steps?: TGridSteps;
}

const Row = ({ gap, rowGap, columnGap, children, style = {}, steps, ...restProps }: IProps) => {
  const styles = componentStyles({ gap, rowGap, columnGap, steps });

  return (
    <Box style={mergeDeepRight(styles.wrap, style)} data-name="row" {...restProps}>
      {children}
    </Box>
  );
};

export default Row;
