import getGridColumnLayoutValue from '../../../fela-utils/get-grid-column-layout-value';
import getGridGapSpacing from '../../../fela-utils/get-grid-gap-spacing';
import mergeStyles from '../../../fela-utils/merge-styles';
import responsiveStylePropQueries from '../../../fela-utils/responsive-style-prop-queries';
import { TGridSteps, TLayout } from '../../../themes/types';
import { FelaCSS } from '../../fela/flowtypes';

interface IStyleProps {
  gap?: TLayout;
  rowGap?: TLayout;
  columnGap?: TLayout;
  steps?: TGridSteps;
}

interface IStyles {
  wrap: FelaCSS;
}

const styles = ({ gap, rowGap, columnGap = 2, steps = 12 }: IStyleProps): IStyles => {
  const rowSpacing = getGridGapSpacing(gap || rowGap);
  const columnSpacing = getGridGapSpacing(gap || columnGap);
  const gridColumns = getGridColumnLayoutValue(steps);

  return {
    wrap: mergeStyles([
      {
        flexShrink: 0,
        display: 'grid',
        width: '100%',
        gridTemplateColumns: `repeat(${steps}, 1fr)`,
        rowGap: rowSpacing,
        columnGap: columnSpacing,
      },
      responsiveStylePropQueries('gridTemplateColumns', gridColumns),
      responsiveStylePropQueries('rowGap', rowSpacing),
      responsiveStylePropQueries('columnGap', columnSpacing),
    ]),
  };
};

export default styles;
