import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  linkWrap: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  linkWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    color: colors.space,

    ':not(:last-of-type)': {
      ':after': {
        content: '"|"',
        display: 'flex',
        marginLeft: 3,
        marginRight: 3,
        fontDefault: 12,
      },
    },
  },
});

export default styles;
