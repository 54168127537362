import { toUrlQuery } from '../toUrlQuery';

export function constructURL(
  source: string,
  parameters: Record<string, Parameters<typeof encodeURIComponent>[0]>,
) {
  const hasProtocol = /^https?:/i.test(source);

  // Relative path is starting with a slash, eg. /api/get-image
  const isRelativePath = /^[/][^/]/.test(source);
  if (isRelativePath) {
    const prefix = source.includes('?') ? '&' : '?';
    return source + toUrlQuery(parameters, prefix);
  }

  const url = new URL(hasProtocol ? source : `https://${source}`);

  for (const [key, value] of Object.entries(parameters)) {
    url.searchParams.append(key, value.toString());
  }

  return hasProtocol ? url.toString() : url.toString().replace(/^https?:/i, '');
}
