import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconFacebook = ({ id, color, width = 6.8, style, ...restProps }: IProps) => {
  const viewBoxWidth = 6.8;
  const viewBoxHeight = 13.4;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M4.5 2.9c0-.5.4-.6.6-.6h1.6V0H4.5C2 0 1.4 1.7 1.4 2.8v1.5H0v2.4h1.4v6.7h3V6.7h2.1l.3-2.4H4.5V2.9zM6.8 4.3z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconFacebook;
