import React, { FC, PropsWithChildren } from 'react';
import { Box, Container } from '@bridebook/ui';
import componentStyles from './footer-container.style';

const FooterContainer: FC<PropsWithChildren> = ({ children }) => {
  const styles = componentStyles();

  return (
    <Box as="footer" style={styles.wrapper} data-name="footer">
      <Container>{children}</Container>
    </Box>
  );
};

export default FooterContainer;
