import { FelaCSS } from '../../fela/flowtypes';
import { SharedTypes } from './imagex';

interface PropTypes extends SharedTypes {
  paddingBottom: string;
  imageStyle?: FelaCSS;
}

interface StyleTypes {
  wrapper: FelaCSS;
  image: FelaCSS;
}

const styles = ({ paddingBottom, style = {}, imageStyle = {} }: PropTypes): StyleTypes => ({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    flexShrink: 0,
    paddingBottom,
    ...style,
  },

  image: {
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    width: '100%',
    position: 'absolute',
    ...imageStyle,
  },
});

export default styles;
