import { useTranslation } from 'next-i18next';
import React, { MouseEventHandler, ReactNode } from 'react';
import { dashify } from '@bridebook/toolbox';
import { A, Box } from '@bridebook/ui';
import BridebookLogo from '@bridebook/ui/src/components/svg/bridebook-logo';
import IconFacebook from '@bridebook/ui/src/components/svg/icon-facebook';
import IconInstagram from '@bridebook/ui/src/components/svg/icon-instagram';
import IconPinterest from '@bridebook/ui/src/components/svg/icon-pinterest';
import IconTwitter from '@bridebook/ui/src/components/svg/icon-twitter';
import { IconTiktok, IconYoutube } from '@bridebook/ui/src/icons';
import LinkPipeList from 'components/bbcommon/footer/link-pipe-list/link-pipe-list';
import TrackLink from 'components/bbcommon/footer/track-link/track-link';
import componentStyles from './footer-bottom.style';

interface IProps {
  onSocialUrlClick?: MouseEventHandler<HTMLAnchorElement>;
  localeSelect: React.ReactNode;
}

const FooterBottom = ({ onSocialUrlClick, localeSelect }: IProps) => {
  const { t } = useTranslation('common');
  const year = new Date().getFullYear();
  const copyrights = `© ${year} Bridebook Ltd`;

  const TRACKED_LINKS_LIST: { url: string; title: string }[] = [
    { url: 'cookie-policy', title: t('footer.cookiePolicy') },
    { url: 'privacy-policy', title: t('footer.privacyPolicy') },
    {
      url: 'terms',
      title: t('footer.terms'),
    },
  ];

  const SOCIAL_MEDIA_LIST: { url: string; icon: ReactNode }[] = [
    {
      url: 'https://www.youtube.com/c/BridebookUk/featured',
      icon: <IconYoutube color="mineShaft" width={16} />,
    },
    {
      url: 'https://www.tiktok.com/@bridebook',
      icon: <IconTiktok color="mineShaft" />,
    },
    {
      url: 'https://www.facebook.com/bridebookhq',
      icon: <IconFacebook color="mineShaft" width={7} />,
    },
    {
      url: 'https://www.pinterest.com/bridebook/',
      icon: <IconPinterest color="mineShaft" width={12} />,
    },
    {
      url: 'https://twitter.com/bridebook',
      icon: <IconTwitter color="mineShaft" width={14} />,
    },
    {
      url: 'https://www.instagram.com/bridebookhq/',
      icon: <IconInstagram color="mineShaft" width={15} />,
    },
  ];

  const styles = componentStyles();

  return (
    <Box py={[8, 12]} style={styles.wrapper} data-name="footer-bottom">
      <Box mb={[5, 5, 0]} style={styles.copyrightWrap}>
        <BridebookLogo color="space" width={129} />
        <Box ml={4}>{copyrights}</Box>
      </Box>
      <Box style={styles.urlsWrap}>
        <Box mb={[5, 5, 0]}>
          <LinkPipeList
            list={TRACKED_LINKS_LIST.map((item) => (
              <TrackLink
                url={item.url}
                title={item.title}
                key={`footer-bottom-tracked-link-${dashify(item.title)}`}
              />
            ))}
          />
        </Box>
        <Box ml={[0, 6]} style={styles.socialMediaContainer}>
          {SOCIAL_MEDIA_LIST.map((item, i) => (
            <A
              mr={i === SOCIAL_MEDIA_LIST.length - 1 ? undefined : 6}
              key={`footer-bottom-social-${item.url}`}
              noUnderline
              href={item.url}
              target="_blank"
              onClick={onSocialUrlClick}>
              {item.icon}
            </A>
          ))}
        </Box>
        {localeSelect && <Box style={styles.localeSelect}>{localeSelect}</Box>}
      </Box>
    </Box>
  );
};

export default FooterBottom;
