import { ILayout } from '../types';
import spacing from './spacing';

const gridSpacing: ILayout = {
  0: spacing['0'],
  1: spacing['2'],
  2: spacing['4'],
  3: spacing['6'],
  4: spacing['8'],
  5: spacing['12'],
  6: spacing['16'],
  7: spacing['24'],
  8: spacing['40'],
};

export default gridSpacing;
