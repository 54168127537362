interface Image {
  imgW: number;
  imgH: number;
  wm?: boolean;
  src?: string;
  h: number;
  portrait?: boolean;
}

/**
 * https://bridebook-images.imgix.net/assets/c.png?w=40
 */
export const wm64 = 'aHR0cHM6Ly9icmlkZWJvb2staW1hZ2VzLmltZ2l4Lm5ldC9hc3NldHMvYy5wbmc_dz00MA';

/**
 * Construct customParams for ImgIX to create landscape image from portrait, add watermark.
 */
export function getWatermarkParams({
  imgW,
  imgH,
  wm,
  src,
  h,
  portrait,
}: Image): Record<string, string | number> {
  if (imgW > 350 && portrait) {
    let mark64 = `${src}?h=${h}&fit=crop&crop=faces`;

    if (wm) {
      mark64 += `&mark64=${wm64}&markalpha=30&markalign=left&markw=20`;
    }

    return {
      mark64,
      markalign: 'center',
      bri: -15,
      con: -55,
      blur: 50,
      markh: imgH,
      marky: 0,
    };
  } else if (wm) {
    return {
      mark: 'https://bridebook-images.imgix.net/assets/c.png',
      markw: 30,
      markalpha: 30,
      markalign: 'left',
    };
  }

  return {};
}
