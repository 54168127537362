import { BaseError } from 'make-error';

class TranslatedError extends BaseError {
  messageI18n?: string;
  constructor(message: string, messageI18n?: string) {
    super(message);
    this.messageI18n = messageI18n;
  }
}

export default TranslatedError;
