interface AnimationTimeType {
  fast: number;
  medium: number;
  slow: number;
}

const animationTime: AnimationTimeType = {
  fast: 150,
  medium: 300,
  slow: 600,
};

export default animationTime;
