import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    borderTop: `1px solid ${colors.space15}`,
  },
});

export default styles;
