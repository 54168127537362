import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconPinterest = ({ id, color, width = 40, style, ...restProps }: IProps) => {
  const viewBoxWidth = 40;
  const viewBoxHeight = 49;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M0 17.584c0 4.841 1.932 9.147 6.077 10.75.68.265 1.29.01 1.486-.703.136-.493.462-1.738.607-2.26.198-.705.12-.952-.428-1.568-1.196-1.336-1.96-3.067-1.96-5.52 0-7.113 5.613-13.478 14.614-13.478 7.97 0 12.35 4.618 12.35 10.785 0 8.114-3.787 14.966-9.41 14.966-3.104 0-5.428-2.435-4.685-5.422.893-3.565 2.62-7.412 2.62-9.987 0-2.303-1.303-4.225-4.002-4.225-3.173 0-5.724 3.115-5.724 7.285 0 2.657.946 4.453.946 4.453S9.244 35.71 8.673 37.996c-1.134 4.552-.17 10.13-.088 10.693.049.334.5.416.705.163.293-.362 4.075-4.79 5.357-9.212.364-1.252 2.09-7.737 2.09-7.737 1.03 1.866 4.045 3.507 7.249 3.507C33.526 35.41 40 27.162 40 16.123 40 7.773 32.544 0 21.21 0 7.11 0 0 9.586 0 17.58v.004z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconPinterest;
