import { IFair as IFairFS } from '@bridebook/models/source/models/Suppliers/Fairs.types';
import { IFeedback } from '@bridebook/models/source/models/Suppliers/Feedback.types';
import { TTimestampedSerialized } from '@bridebook/toolbox/src/serialize-timestamps';
import { IElasticSupplier as IToolboxElasticSupplier, IUISupplier } from '@bridebook/toolbox/src/types';
import type { ShortlistedLocation } from 'lib/shortlist/types';

export type TRefSupplier = {
  supplierId?: string;
  supplierName?: string;
  viewedLocation:
    | 'linkedSearchTile'
    | 'linkedSupplierProfile'
    | 'linkedSupplier_weddingConfirmationPopup'
    | 'venueRecommendedSuppliers'
    | 'map';
};

export type SupplierCardInView = {
  supplier: IUISupplier;
  supplierTileOrder?: number;
  viewedLocation?: ShortlistedLocation;
  refSupplier?: TRefSupplier;
};

export type ViewedEndScreen = {
  imagesCount: number;
  location: 'search' | 'supplierProfile';
  firstImageUrl: string;
};

export interface IFair extends Omit<IFairFS, 'dates'> {
  dates: number[];
}

export interface IFeedbackWithExtras extends IFeedback {
  userPhoto?: string;
}

export interface IFeedbackSerialized
  extends Omit<IFeedbackWithExtras, 'createdAt' | 'updatedAt' | 'dateWedding' | 'id' | 'response'> {
  createdAt: number;
  updatedAt: number;
  dateWedding: number;
  id?: string;
  response: TTimestampedSerialized<Required<IFeedback>['response']>;
}

interface Review {
  createdAt: number;
  date: string;
  email: string;
  from: string;
  id: string;
  isReview: boolean;
  message: string;
  name: string;
  stars: number;
  title: string;
}

export interface Reviews {
  activePage: number;
  reviews: Review[];
  feedback?: Partial<IFeedbackSerialized>[];
  hasReviews: boolean;
  hasTestimonials: boolean;
  stars: number;
  loading: boolean;
}

export type ISupplierRelation = Pick<
  IToolboxElasticSupplier,
  | 'id'
  | 'type'
  | 'publicId'
  | 'name'
  | 'town'
  | 'county'
  | 'country'
  | 'thumbnail'
  | 'reviewsStars'
  | 'photos'
  | 'countryCode'
  | 'email'
  | 'distanceFromOrigin'
>;

export enum PricingCategory {
  Affordable = 1,
  Moderate = 2,
  Luxury = 3,
  SuperLuxury = 4,
}

export interface InstagramMedia {
  url: string;
  thumbnailUrl: string;
  id: string;
  caption?: string;
}
