import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import type { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { capitalizeFirstLetter } from '@bridebook/toolbox/src/utils/strings';
import { getI18n } from 'lib/i18n/getI18n';

type PrettySupplierCategory = (o: {
  slug?: Slug | UrlFriendlySlug | '';
  amount: number;
  isLoggedIn: boolean;
  addWedding?: boolean;
  capitalize?: boolean;
  market: Market;
}) => string;

/**
 * Function `prettySupplierCategory` return pretty category based on slug
 * Additional parameters - singular / plural
 * Add 'Wedding' prefix
 * Whether to Capitalize
 */
export const prettySupplierCategory: PrettySupplierCategory = ({
  slug,
  amount,
  addWedding,
  capitalize,
  market,
  isLoggedIn,
}) => {
  const i18n = getI18n();

  if (!slug) {
    return '';
  }

  const photo = i18n.t('category.photographer', {
    count: amount,
  });
  const video = i18n.t('category.videographer', {
    count: amount,
  });
  const catering = i18n.t('category.catering', {
    count: amount,
  });
  const transport = i18n.t('category.transport', {
    count: amount,
  });
  const stationery = i18n.t('category.stationery', {
    count: amount,
  });
  const planners = i18n.t('category.planners', {
    count: amount,
  });
  const dressAccessories = i18n.t('category.dress', {
    count: amount,
  });
  const beauty = i18n.t('category.beauty', { count: amount });
  const jewellery = i18n.t('category.jewellery', { count: amount });
  const decoration = i18n.t('category.decoration', {
    count: amount,
  });
  const venues = isLoggedIn
    ? i18n.t('category.venues', {
        count: amount,
      })
    : i18n.t('category.venues.loggedOut', {
        count: amount,
      });
  const marquee = i18n.t('category.marquee', {
    count: amount,
  });
  const menswear = i18n.t('category.menswear', {
    count: amount,
  });
  const entertainment = i18n.t('category.entertainment', { count: amount });
  const music = i18n.t('category.music', { count: amount });
  const cakes = i18n.t('category.cakes', {
    count: amount,
  });
  const florist = i18n.t('category.florist', {
    count: amount,
  });

  const slugToText: Record<string, string> = {
    'beauty-hair-makeup': beauty,
    'decoration-hire': decoration,
    'dress-accessories': dressAccessories,
    'marquee-hire': marquee,
    'planners-toastmasters-celebrants': planners,
    'rings-jewellery': jewellery,
    'musicians-bands-djs': music,
    beauty,
    cakes,
    catering,
    decoration,
    dress: dressAccessories,
    entertainment,
    florist,
    florists: florist,
    jewellery,
    marquee,
    menswear,
    music,
    photo,
    photographers: photo,
    planners,
    stationery,
    transport,
    venue: venues,
    venues,
    video,
    videographers: video,
  };

  const prettyCategory = slugToText[slug] || slug;

  if (!slugToText[slug]) {
    // eslint-disable-next-line no-console
    console.warn('slug not in object list, please add it: ' + slug);
  }

  const capitalised = capitalize ? capitalizeFirstLetter(prettyCategory) : prettyCategory;

  // TODO: [i18n][bb-global] Find a better solution...
  return addWedding && market.country !== CountryCodes.DE
    ? getI18n().t('category.withWedding', {
        category: capitalised,
      })
    : capitalised;
};
