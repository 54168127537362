import { mergeDeepRight } from 'ramda';
import React, { FC } from 'react';
import { Box } from '@bridebook/ui';
import colorParse from '../../fela-utils/color-parse';
import { IIconProps } from '../../types';
import componentStyles from './icon-default-styles';

interface SimpleIconWrapProps extends IIconProps {
  viewBox: [number, number, number, number];
  path: string;
}

const SimpleIconWrap: FC<SimpleIconWrapProps> = ({
  onClick,
  width,
  style = {},
  id,
  viewBox,
  color,
  path,
  strokeWidth,
  strokeColor,
}: SimpleIconWrapProps) => {
  const [minX, minY, viewBoxWidth, viewBoxHeight] = viewBox;
  const height = getIconHeight(width, viewBoxHeight, viewBoxWidth);
  const styles = componentStyles();
  const customStyles = mergeDeepRight(styles.icon, style);
  return (
    <Box
      as="svg"
      onClick={onClick}
      id={id}
      viewBox={`${minX} ${minY} ${viewBoxWidth} ${viewBoxHeight}`}
      x={0}
      y={0}
      width={`${width}px`}
      height={`${height}px`}
      style={customStyles}>
      <path
        fill={colorParse(color) || color}
        fillRule="evenodd"
        d={path}
        strokeWidth={strokeWidth}
        stroke={colorParse(strokeColor)}
      />
    </Box>
  );
};

export default SimpleIconWrap;

const getIconHeight = (width: number, viewHeight: number, viewWidth: number) =>
  Math.round(width * (viewHeight / viewWidth));
