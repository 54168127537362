import { useTranslation } from 'next-i18next';
import { MouseEventHandler, ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { A, Box, Col, Row } from '@bridebook/ui';
import { IconChevronFat } from '@bridebook/ui/src/icons/dynamic';
import FooterAppBadges from 'app-shared/components/footer/footer-app-badges/footer-app-badges';
import { Link } from 'app-shared/components/link/link';
import { env } from 'lib/env';
import {
  LandingPageIds,
  WebflowLandingPages,
  landingPageExists,
} from 'lib/landing/webflow-page-urls';
import { getCareersUrl } from 'lib/url-helper';
import componentStyles from './footer-top.style';

interface IProps {
  hasDirectory?: boolean;
  isCollapsible?: boolean;
  isCordova?: boolean;
  market: Market;
  onAppBadgeClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

export interface IFooterLinkItem {
  title: string;
  href: string;
  openInNewTab?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

interface IFooterColumnData {
  heading: string;
  options?: IFooterLinkItem[];
  customContent?: ReactNode;
}

const FooterTop = ({ isCollapsible, isCordova, onAppBadgeClick, market }: IProps) => {
  const { t } = useTranslation('common');
  const [contentShown, setContentShown] = useState(false);
  const cmsLandingUrl = market.flags.monetized
    ? `${env.CMS_LANDING_URL}/${market.prefix}`
    : `${env.CMS_LANDING_URL}/`;

  const toggledExpand = useCallback(() => {
    setContentShown(!contentShown);
  }, [contentShown]);

  const aboutContent: IFooterColumnData = {
    heading: t('footer.about.heading'),
    options: [
      ...(landingPageExists(LandingPageIds.aboutUs, market)
        ? [
            {
              title: t('footer.about.aboutUs'),
              href: WebflowLandingPages[LandingPageIds.aboutUs].bridebookPath,
            },
          ]
        : []),
      {
        title: t('footer.about.hiring'),
        href: getCareersUrl(market, 'footer'),
        openInNewTab: true,
      },
      ...(landingPageExists(LandingPageIds.pressAndMedia, market)
        ? [
            {
              title: t('footer.about.media'),
              href: WebflowLandingPages[LandingPageIds.pressAndMedia].bridebookPath,
            },
          ]
        : []),
      ...(landingPageExists(LandingPageIds.contactUs, market)
        ? [
            {
              title: t('footer.about.contact'),
              href: WebflowLandingPages[LandingPageIds.contactUs].bridebookPath,
            },
          ]
        : []),
    ],
  };

  const businessContent: IFooterColumnData = {
    heading: t('footer.business.heading'),
    options: [
      {
        title: t('footer.business.bridebook'),
        href: cmsLandingUrl,
        openInNewTab: true,
      },
    ],
  };

  const couplesContent: IFooterColumnData = {
    heading: t('footer.couples.heading'),
    options: [
      ...(landingPageExists(LandingPageIds.weddingPlanningTools, market)
        ? [
            {
              title: t('footer.couples.planning'),
              href: WebflowLandingPages[LandingPageIds.weddingPlanningTools].bridebookPath,
            },
          ]
        : []),
      ...(landingPageExists(LandingPageIds.weddingSuppliers, market)
        ? [
            {
              title: t('footer.couples.supplierDirectory'),
              href: WebflowLandingPages[LandingPageIds.weddingSuppliers].bridebookPath,
            },
          ]
        : []),
      ...(landingPageExists(LandingPageIds.weddingIdeasInspiration, market)
        ? [
            {
              title: t('footer.couples.ideas'),
              href: WebflowLandingPages[LandingPageIds.weddingIdeasInspiration].bridebookPath,
            },
          ]
        : []),
      ...(landingPageExists(LandingPageIds.weddingGiftLists, market)
        ? [
            {
              title: t('footer.couples.giftList'),
              href: WebflowLandingPages[LandingPageIds.weddingGiftLists].bridebookPath,
            },
          ]
        : []),
    ],
  };

  const appBadgesContent: IFooterColumnData = {
    heading: t('footer.getApp.heading'),
    customContent: <FooterAppBadges market={market} onClick={onAppBadgeClick} />,
  };

  const list: IFooterColumnData[] = [
    aboutContent,
    businessContent,
    ...(couplesContent.options?.length ? [couplesContent] : []),
    ...(isCordova ? [] : [appBadgesContent]),
  ];

  const styles = componentStyles({ contentShown });

  return (
    <Box data-name="footer-top" style={styles.wrapper}>
      {isCollapsible && (
        <Box style={styles.controlsWrap}>
          <Box as="button" style={styles.button} onClick={toggledExpand}>
            <Box style={styles.buttonText}>{t('footer.more')}</Box>
            <Box style={styles.buttonIconWrap}>
              <IconChevronFat color="alto" width={15} />
            </Box>
          </Box>
        </Box>
      )}
      <Collapse isOpened={contentShown || !isCollapsible}>
        <Row style={styles.contentContainer}>
          {list.map((category) => (
            <Col xs={12} sm={3} style={styles.column} key={`footer-category-${category.heading}`}>
              <Box as="h5" mb={5} style={styles.heading}>
                {category.heading}
              </Box>
              {category.customContent ? (
                category.customContent
              ) : category.options ? (
                <Box as="ul" style={styles.columnList}>
                  {category.options.map(({ href, onClick, openInNewTab, title }, i) => (
                    <Box as="li" key={`footer-option-${i}`}>
                      <Link href={href} passHref legacyBehavior>
                        <A
                          onClick={onClick}
                          color="space"
                          target={openInNewTab ? '_blank' : undefined}
                          style={styles.link}>
                          {title}
                        </A>
                      </Link>
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Col>
          ))}
        </Row>
      </Collapse>
    </Box>
  );
};

export default FooterTop;
