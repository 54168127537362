import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconInstagram = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 48;
  const viewBoxHeight = 48;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M24.98 33.307a8.326 8.326 0 0 1-8.327-8.327 8.326 8.326 0 0 1 8.326-8.326 8.326 8.326 0 0 1 8.326 8.326 8.326 8.326 0 0 1-8.326 8.327m0-20.571c-6.763 0-12.245 5.482-12.245 12.244 0 6.764 5.482 12.245 12.245 12.245 6.763 0 12.245-5.481 12.245-12.245 0-6.762-5.482-12.244-12.245-12.244M34.36 0H13.64C6.109 0 0 6.14 0 13.71v20.581C0 41.866 6.105 48 13.64 48h20.72C41.891 48 48 41.862 48 34.291V13.71C48 6.136 41.894 0 34.36 0zm9.721 34.265c0 5.42-4.371 9.817-9.758 9.817H13.677c-5.391 0-9.759-4.393-9.759-9.817V13.737c0-5.421 4.372-9.818 9.759-9.818h20.646c5.391 0 9.758 4.394 9.758 9.818v20.528zM36.245 8.817a2.94 2.94 0 1 0 0 5.878 2.94 2.94 0 0 0 0-5.878"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconInstagram;
