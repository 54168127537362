import { FelaCSS, colors, transition } from '@bridebook/ui';

interface IProps {
  contentShown: boolean;
}

interface IStyles {
  wrapper: FelaCSS;
  contentContainer: FelaCSS;
  column: FelaCSS;
  heading: FelaCSS;
  controlsWrap: FelaCSS;
  button: FelaCSS;
  buttonText: FelaCSS;
  buttonIconWrap: FelaCSS;
  columnList: FelaCSS;
  link: FelaCSS;
}

const styles = ({ contentShown }: IProps): IStyles => ({
  wrapper: {
    borderBottom: `1px solid ${colors.space15}`,
  },

  contentContainer: {
    paddingTop: '40px',
    paddingBottom: '40px',

    maxWidthXsMax: {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '15px',
      paddingBottom: '25px',
    },
  },

  column: {
    maxWidthXsMax: {
      ':not(:last-of-type)': {
        marginBottom: '25px',
      },
    },
  },

  heading: {
    marginTop: 0,
    fontDefault: 13,
    lineHeight: '15px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: colors.space40,
  },

  controlsWrap: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },

  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '26px',
    paddingBottom: '26px',
    width: '100%',
    textAlign: 'left',
    fontDefaultSemiBold: 13,
    lineHeight: '14px',
    textTransform: 'uppercase',
    color: colors.alto,
    background: 'none',
    border: 'none',
  },

  buttonText: {
    flexGrow: 1,
  },

  buttonIconWrap: {
    transform: contentShown ? 'rotate(180deg)' : 'rotate(0)',
    transition: `transform ${transition.fast} ease-in-out`,
  },

  columnList: {
    // This prevents some styling added to lists in pages by Webflow
    paddingLeft: 0,
  },

  link: {
    fontDefault: 15,
    lineHeight: '2em',
    color: colors.space,
  },
});

export default styles;
