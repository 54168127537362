import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierPlanner = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5918 6.70801L8.6828 8.04201L10.5918 5.70801"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91309 10.708L9.00409 12.042L10.9131 9.70801"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.47998 14.708L8.57098 16.042L10.48 13.708"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5918 7.375H15.5918"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9131 11.375H15.9131"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.48 15.375H15.48"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7469 17.147C17.7809 17 17.8129 16.853 17.8469 16.705C18.6959 12.1759 18.4778 7.51119 17.2099 3.08102C17.1467 2.87558 17.019 2.69597 16.8458 2.56881C16.6725 2.44164 16.4628 2.37369 16.2479 2.37502H5.33591C5.18173 2.37568 5.02979 2.41199 4.89197 2.48109C4.75415 2.5502 4.63417 2.65025 4.54141 2.7734C4.44866 2.89656 4.38563 3.0395 4.35727 3.19105C4.32891 3.3426 4.33597 3.49865 4.37791 3.64702C6.83191 12.213 3.98291 16.79 3.98291 19.875C3.9833 20.0881 4.02907 20.2986 4.11718 20.4926C4.20529 20.6866 4.3337 20.8596 4.49389 21C4.65407 21.1405 4.84234 21.2452 5.04616 21.3073C5.24999 21.3693 5.46469 21.3872 5.67597 21.3598C5.88725 21.3324 6.09027 21.2603 6.2715 21.1482C6.45274 21.0362 6.60803 20.8869 6.72705 20.7102C6.84606 20.5335 6.92607 20.3334 6.96174 20.1234C6.99741 19.9133 6.98792 19.6981 6.93391 19.492C6.91757 19.4297 6.91572 19.3644 6.92851 19.3013C6.94129 19.2381 6.96837 19.1788 7.00766 19.1277C7.04695 19.0766 7.09741 19.0352 7.15518 19.0067C7.21294 18.9781 7.27648 18.9632 7.34091 18.963H19.4409C19.5184 18.9631 19.5944 18.9845 19.6606 19.0249C19.7267 19.0653 19.7805 19.1231 19.8159 19.192C19.9329 19.4207 19.9892 19.6756 19.9793 19.9323C19.9695 20.189 19.8939 20.4389 19.7598 20.658C19.6258 20.8771 19.4376 21.0581 19.2135 21.1837C18.9894 21.3092 18.7368 21.3751 18.4799 21.375H5.47991"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierPlanner;
