import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';

// TODO refactor this - This whole function seems terribly buggy. We assume default type as Partial<ISupplier> yet at the same time we're ignoring the fact, that ie. town might be undefined.
//  Basically any value with ! is a string or undefined but we will never know about it.
//  From the perspective of this function client, the town is always string except that it's not.

export function extractSupplier<S extends ISupplier | Partial<ISupplier> = Partial<ISupplier>>(
  supplier: S,
) {
  const hideFirstAddressLine = Boolean(supplier?.flags?.hideStreet);
  const address = supplier?.address?.street?.[0];
  const town = supplier?.address?.city!;
  const postcode = supplier?.address?.postalCode!;
  const county = supplier?.address?.adminArea?.[0]!;
  // @TODO: [i18n] Should this be typed as CountryCodes? Seems wrong!
  const country = supplier?.address?.adminArea?.[1] || supplier?.address?.country!;
  const countryCode = supplier?.l10n?.country;
  const phone = supplier?.contacts?.phone!;
  const email = supplier?.contacts?.email!;
  const website = supplier?.contacts?.website!;
  const slug = supplier?.type?.[0];
  const photosCount = supplier?.counters?.photos || 0;

  // @ts-expect-error
  const lat = supplier?.coordinates?._latitude;
  // @ts-expect-error
  const lng = supplier?.coordinates?._longitude;
  const type = supplier?.type?.[0]!;
  const description = supplier?.description?.short;
  const detailedDescription = supplier?.description?.long;
  const venueType = supplier?.typeDetails?.venue?.type;
  // Are dropped in migration
  const category = type;
  const visibleInMainSearch = true;
  // Under discussion
  const noindex = false;
  const supplierRegistered = (supplier?.users ?? []).length > 0;

  return {
    ...(supplier as Omit<S, 'type'>),
    type,
    category,
    noindex,
    visibleInMainSearch,
    hideFirstAddressLine,
    address,
    town,
    postcode,
    county,
    country,
    countryCode,
    lat,
    lng,
    description,
    detailedDescription,
    supplierRegistered,
    phone,
    email,
    website,
    venueType,
    slug,
    photosCount,
  };
}
