import slugify from 'slugify';
import { ICountry } from '@bridebook/models/source/models/Countries.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

/**
 * Prepares "area" string for fetching location-info from RTDB.
 * For instance for "Normandie-France--Normandie--FR":
 *    1. it removes -- suffix (i.e. --Normandie--FR);
 *    2. it replaces white spaces with -
 *    3. it converts to lower case
 *    4. it returns formatted string (i.e. "normandie")
 * @param {string} area
 * @returns {string} - formatted area
 */
export const formatArea = (area: string): string => {
  const slugifyOpts = { lower: true, replacement: '-', remove: /'/ };
  return slugify(area.split('--')[0], slugifyOpts);
};

/**
 * This will cover area searches like for example Uk Gb, De where we want to display the capitalised area (fix for LIVE-20987)
 * @param {string} area - The area to check for capitalization
 * @param {string} countryCode - The country code
 * @param {string} [countryAlias] - The alias of the country (if it has one). For example GB has UK as an alias
 * @returns {boolean} True if the area should be capitalised, false otherwise
 */
export const isCountryLevelSearch = (
  area: string,
  countryCode: CountryCodes,
  countryAlias?: ICountry['alias'],
): boolean =>
  area.toUpperCase() === countryCode.toUpperCase() ||
  area.toUpperCase() === countryAlias?.toUpperCase();
