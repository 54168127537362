import { Market } from '@bridebook/toolbox/src/gazetteer';
import { UrlHelper } from 'lib/url-helper';

export enum LandingPageIds {
  localeHome = 'localeHome',
  weddingPlanningTools = 'weddingPlanningTools',
  toolsGuestlist = 'toolsGuestlist',
  toolsShortlist = 'toolsShortlist',
  toolsBudget = 'toolsBudget',
  toolsChecklist = 'toolsChecklist',
  weddingGiftLists = 'weddingGiftLists',
  bridesmaidDresses = 'bridesmaidDresses',
  weddingSuppliers = 'weddingSuppliers',
  weddingIdeasInspiration = 'weddingIdeasInspiration',
  termsConditions = 'termsConditions',
  privacyPolicy = 'privacyPolicy',
  cookiePolicy = 'cookiePolicy',
  aboutUs = 'aboutUs',
  contactUs = 'contactUs',
  pressAndMedia = 'pressAndMedia',
  appSupport = 'appSupport',
}

// Webflow folder names are equal to market prefix, the only different one is a universal `global` folder.
export const WebflowFolders = {
  global: 'global',
  UK: 'uk',
  DE: 'de',
  FR: 'fr',
  IE: 'ie',
  FR_EN: 'fr-en',
};

// Helper constant for all folders existing in Webflow
const allFolders = Object.values(WebflowFolders);

// Main locale folders (global, DE, FR), that more specific locales can fallback to (eg. en-IE -> global)
const mainLocaleFolders = [WebflowFolders.global, WebflowFolders.DE, WebflowFolders.FR];

/**
 * Returns a list of existing Webflow Landing Pages
 */
export const WebflowLandingPages: Record<
  LandingPageIds,
  {
    // Path to the page on Bridebook (without market prefix)
    bridebookPath: string;
    // Path to the page on Webflow (without the first-level country folder)
    webflowPath: string;
    // Specifies in which Webflow folders a landing page exists in Webflow.
    // Can be either a specific country folder or a universal global folder.
    folders: string[];
  }
> = {
  /* ############################################################################
   * Pages within ALL folders
   * ######################################################################### */
  [LandingPageIds.localeHome]: {
    bridebookPath: UrlHelper.index,
    webflowPath: '/home',
    folders: allFolders,
  },
  [LandingPageIds.weddingPlanningTools]: {
    bridebookPath: UrlHelper.weddingPlanningTools.main,
    webflowPath: UrlHelper.weddingPlanningTools.main,
    folders: allFolders,
  },
  [LandingPageIds.toolsGuestlist]: {
    bridebookPath: UrlHelper.weddingPlanningTools.guestlist,
    webflowPath: UrlHelper.weddingPlanningTools.guestlist,
    folders: allFolders,
  },
  [LandingPageIds.toolsShortlist]: {
    bridebookPath: UrlHelper.weddingPlanningTools.shortlist,
    webflowPath: UrlHelper.weddingPlanningTools.shortlist,
    folders: allFolders,
  },
  [LandingPageIds.toolsBudget]: {
    bridebookPath: UrlHelper.weddingPlanningTools.budget,
    webflowPath: UrlHelper.weddingPlanningTools.budget,
    folders: allFolders,
  },
  [LandingPageIds.toolsChecklist]: {
    bridebookPath: UrlHelper.weddingPlanningTools.checklist,
    webflowPath: UrlHelper.weddingPlanningTools.checklist,
    folders: allFolders,
  },
  [LandingPageIds.weddingSuppliers]: {
    bridebookPath: UrlHelper.search,
    webflowPath: '/wedding-suppliers',
    folders: allFolders,
  },
  [LandingPageIds.weddingIdeasInspiration]: {
    bridebookPath: UrlHelper.weddingIdeasInspiration,
    webflowPath: UrlHelper.weddingIdeasInspiration,
    folders: allFolders,
  },
  /* ############################################################################
   *  Market-specific pages only
   * ######################################################################### */
  [LandingPageIds.weddingGiftLists]: {
    bridebookPath: UrlHelper.weddingGiftLists,
    webflowPath: UrlHelper.weddingGiftLists,
    folders: [WebflowFolders.UK],
  },
  [LandingPageIds.bridesmaidDresses]: {
    bridebookPath: UrlHelper.bridesmaidDresses,
    webflowPath: UrlHelper.bridesmaidDresses,
    folders: [WebflowFolders.UK],
  },
  /* ############################################################################
   *  Main locale folders only (global, DE, FR)
   * ######################################################################### */
  [LandingPageIds.termsConditions]: {
    bridebookPath: UrlHelper.terms,
    webflowPath: UrlHelper.terms,
    folders: mainLocaleFolders,
  },
  [LandingPageIds.privacyPolicy]: {
    bridebookPath: UrlHelper.privacyPolicy,
    webflowPath: UrlHelper.privacyPolicy,
    folders: mainLocaleFolders,
  },
  [LandingPageIds.cookiePolicy]: {
    bridebookPath: UrlHelper.cookiePolicy,
    webflowPath: UrlHelper.cookiePolicy,
    folders: mainLocaleFolders,
  },
  [LandingPageIds.aboutUs]: {
    bridebookPath: UrlHelper.aboutUs,
    webflowPath: UrlHelper.aboutUs,
    folders: mainLocaleFolders,
  },
  [LandingPageIds.contactUs]: {
    bridebookPath: UrlHelper.contactUs,
    webflowPath: UrlHelper.contactUs,
    folders: mainLocaleFolders,
  },
  [LandingPageIds.pressAndMedia]: {
    bridebookPath: UrlHelper.pressAndMedia,
    webflowPath: UrlHelper.pressAndMedia,
    folders: mainLocaleFolders,
  },
  [LandingPageIds.appSupport]: {
    bridebookPath: UrlHelper.appSupport,
    webflowPath: UrlHelper.appSupport,
    folders: mainLocaleFolders,
  },
};

/**
 * Returns the fallback folder that can be used for the given market.
 * If undefined is returned, then there's no fallback folder and it requires an exact match.
 *
 * For good SEO, a fallback folder should have the same language as the main one.
 */
export const getWebflowFallbackFolder = (market: Market) => {
  switch (market.prefix) {
    case WebflowFolders.FR:
    case WebflowFolders.DE:
      // Returns undefined because there's no other folder in these languages.
      return;
    default:
      return WebflowFolders.global;
  }
};

/**
 * Returns true if a specific LandingPageIds exists for a given market
 * (either as a market-specific page or a fallback page, eg. global)
 */
export const landingPageExists = (pageId: LandingPageIds, market: Market) => {
  const fallbackFolder = getWebflowFallbackFolder(market);
  return (
    WebflowLandingPages[pageId].folders.includes(market.prefix) ||
    (fallbackFolder && WebflowLandingPages[pageId].folders.includes(fallbackFolder))
  );
};

export const getIsPathLandingPage = (pathname: string) =>
  [
    UrlHelper.weddingPlanningTools.main,
    UrlHelper.weddingIdeasInspiration,
    UrlHelper.weddingGiftLists,
    UrlHelper.bridesmaidDresses,
    UrlHelper.terms,
    UrlHelper.privacyPolicy,
    UrlHelper.cookiePolicy,
    UrlHelper.aboutUs,
    UrlHelper.contactUs,
    UrlHelper.pressAndMedia,
    UrlHelper.appSupport,
  ].some((landingPagePathname) => pathname.includes(landingPagePathname));
