import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSearch = ({ id, color, width = 12, style, ...restProps }: IProps) => {
  const viewBoxWidth = 12;
  const viewBoxHeight = 14;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M4.81 1.138a3.674 3.674 0 0 0-2.92 5.899 3.688 3.688 0 0 0 5.155.699 3.649 3.649 0 0 0 1.418-2.43 3.645 3.645 0 0 0-.718-2.718 3.656 3.656 0 0 0-2.433-1.416 3.749 3.749 0 0 0-.503-.034M8.17 8.27l3.198 4.197a.568.568 0 0 1-.453.913.569.569 0 0 1-.454-.224L7.261 8.959c-.757.446-1.6.662-2.438.662a4.817 4.817 0 0 1-3.84-1.896A4.811 4.811 0 0 1 5.465.045a4.789 4.789 0 0 1 3.186 1.854 4.773 4.773 0 0 1 .94 3.56A4.772 4.772 0 0 1 8.168 8.27z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSearch;
