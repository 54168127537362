/* eslint-disable */
import type { CountryCodes, firebase } from '../../../firebase/firestore';

export interface ISupplierAdmin {
  actions: {
    checked: null | boolean | firebase.firestore.Timestamp;
    deleted: null | boolean | firebase.firestore.Timestamp;
    delisted: null | boolean | firebase.firestore.Timestamp;
    searchable: null | boolean | firebase.firestore.Timestamp;
  };
  /**
   * List of ISO country codes representing additional countries from where the supplier can be found in searches. Use `*` for global availability.
   */
  additionalCountries?: (CountryCodes | '*')[];
  /**
   * Whether to translate content for all languages, or only specific languages.
   */
  automaticTranslations?: boolean | string[];
  bestOfBritain?: boolean;
  createdAt: firebase.firestore.Timestamp;
  dropped?: boolean;
  droppedAt?: firebase.firestore.Timestamp;
  fakeProfile: boolean;
  hasBadDebt?: {
    cms?: boolean;
    coupleside?: boolean;
    updatedAt?: firebase.firestore.Timestamp;
  };
  payPerBooking?: boolean;
  /**
   * @deprecated Pay Per Enquiry Feature Flag
   */
  payPerEnquiry?: boolean;
  premium?: {
    active: boolean;
    createdAt: firebase.firestore.Timestamp;
    tier?: PremiumTiers | number;
    trial?: {
      activatedAt?: firebase.firestore.Timestamp;
      active?: boolean;
    };
    updatedAt?: firebase.firestore.Timestamp;
  };
  registrationDate?: firebase.firestore.Timestamp;
  searchBoost?: SearchBoostValues | number;
  searchBoostActive?: boolean;
  /**
   * @deprecated This field is legacy, and should be removed.
   */
  searchBoostActiveMonths?: string[];
  searchBoostForType?: (
    | 'barn'
    | 'castle'
    | 'cityHotelCityVenue'
    | 'conferenceCentre'
    | 'countryHouseManorHouse'
    | 'cruiseBoatYacht'
    | 'gardenOutdoor'
    | 'golfCourse'
    | 'hotel'
    | 'museumAttraction'
    | 'otherVenueType'
    | 'placeOfWorship'
    | 'pubRestaurant'
    | 'resort'
    | 'rooftop'
    | 'sportingVenueStadium'
    | 'statelyHome'
    | 'townHallRegistryOffice'
    | 'uniqueVenueType'
    | 'warehouseFactory'
    | 'winery'
  )[];
  searchBoostForTypeActive?: boolean;
  seoIndex: boolean;
  updatedAt?: firebase.firestore.Timestamp;
}

/**
 * Unverified
 * Novice
 * Basic
 * Lite/Classic
 * Classic
 * Expert
 * Platinum
 */
export enum PremiumTiers {
  Unverified = -1,
  Tier_0 = 0,
  Tier_1 = 1,
  Tier_2 = 2,
  Tier_2_5 = 2.5,
  Tier_3 = 3,
  Tier_4 = 4,
}
export enum SearchBoostValues {
  TopSpot = 100,
}
