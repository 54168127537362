import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconCrossBold = ({ id, color, width = 16, style, ...restProps }: IProps) => {
  const viewBoxWidth = 16;
  const viewBoxHeight = 16;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M15.6 13.5L10 8l5.5-5.5c.6-.6.6-1.5 0-2-.6-.6-1.5-.6-2 0L8 6 2.5.4C1.9-.2 1-.2.5.4c-.6.6-.6 1.5 0 2L6 8 .5 13.5c-.6.6-.6 1.5 0 2 .2.3.5.5.9.5s.7-.1 1-.4L8 10l5.5 5.5c.6.6 1.5.6 2 0 .6-.5.6-1.4.1-2z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconCrossBold;
