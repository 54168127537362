export const getSocialShareUTM = (info: any, platform: string): string => {
  const source = platform === 'email' ? 'ss_email_link' : platform;
  const medium = platform === 'email' ? 'email' : 'social';
  const UTM =
    info.type === 'supplier'
      ? `?utm_source=${source}&utm_medium=${medium}&utm_content=ss_button_${platform}_pa&utm_campaign=userSupplierSocialShareOngoing_${info.category}`
      : `?utm_source=${source}&utm_medium=${medium}&utm_content=ss_button_${platform}_aa&utm_campaign=userArticleSocialShareOngoing`;
  return UTM;
};

/**
 * For pages within "https://bridebook.com", it doesn't need the "nofollow",
 * but it should have it for all the duplicated pages sites like
 * "https://app.bridebook.com" or "https://app-proxy-dev.bridebook.com"
 */
export const getRobotsNoIndexContent = (isMainLiveCDN: boolean, isEmptyFacet = true) =>
  'noindex' + (isMainLiveCDN && isEmptyFacet ? '' : ', nofollow');
