import TranslatedError from '../translation/translated-error';

class ValidationError<P = string> extends TranslatedError {
  prop: P;
  code?: string;
  constructor(message: string, prop: P, code?: string, messageI18n?: string) {
    super(message, messageI18n);
    this.prop = prop;
    this.code = code;
  }
}

export default ValidationError;
