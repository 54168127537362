import { Base64 } from 'js-base64';
import { constructURL } from '@bridebook/toolbox/src/constructURL';

const DEFAULT_OPTIONS = Object.freeze({
  auto: 'format,compress', // https://docs.imgix.com/apis/rendering/auto/auto#format
  ch: 'DPR,Save-Data', // https://docs.imgix.com/apis/rendering/format/ch
  cs: 'srgb', // https://docs.imgix.com/apis/rendering/format/cs
  dpr: 1, // https://docs.imgix.com/apis/rendering/pixel-density/dpr
});

/**
 * Construct a URL for an image with an Imgix proxy, expanding image options
 * per the [API reference docs](https://www.imgix.com/docs/reference).
 *
 * @param  {String}  source  raw image
 * @param  {Object}  options map of image API options, in long or short form per expansion rules
 * @param  {Boolean} isVenue boolean indicating whether this image is for a venue
 */
export function processImage(
  source: string | undefined,
  options: Record<string, PropertyKey>,
  isVenue = false,
) {
  if (!source) {
    return '';
  }

  const parameters: Record<string, PropertyKey> = { ...DEFAULT_OPTIONS };

  /**
   * For venues, we want to do automatic visual enhancement.
   * https://docs.imgix.com/apis/rendering/auto/auto#enhance
   */
  if (isVenue === true) {
    parameters['auto'] = 'format,compress,enhance';
  }

  for (const [key, value] of Object.entries(options)) {
    parameters[encodeURIComponent(key)] = key.endsWith('64') ? Base64.encodeURI(value) : value;
  }

  return constructURL(source, parameters);
}
