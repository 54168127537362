import animationTime from './animationTime';

interface TransitionTypes {
  fast: string;
  medium: string;
  slow: string;
}

const convertAnimationTime = (time: number) => time / 1000;

const transition: TransitionTypes = {
  fast: `${convertAnimationTime(animationTime.fast)}s`,
  medium: `${convertAnimationTime(animationTime.medium)}s`,
  slow: `${convertAnimationTime(animationTime.slow)}s`,
};

export default transition;
