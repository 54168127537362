import { TLayout, TLayoutStep } from '../../../themes/types';
import gridSpacing from '../../../themes/variables/grid-spacing';

export const step = (setStep: TLayoutStep) => gridSpacing[setStep];

export const getColWidth = (size: number) => `${Math.round((100 / 12) * size)}%`;

export const getColOffset = (offset: number) => getColWidth(offset > 0 ? offset - 1 : 0);

export const getFlexBasis = (width: string, cGap: TLayout = 2) =>
  `calc(${width} - ${step((cGap * 2) as TLayout)})`;
