import { getExt } from '@bridebook/toolbox/src/getExt';
import { ISupplierPhoto } from 'app-shared/lib/supplier/supplier-types';

const convertSupplierPhoto = (photo: { path: string; portrait?: boolean }) => {
  const path = photo.path;
  const portrait = photo.portrait;
  const ext = getExt(path);
  const supplierPhoto = {
    public_id: path?.replace(`.${ext}`, '') || '',
    ext,
  } as ISupplierPhoto;

  if (typeof portrait === 'boolean') {
    supplierPhoto.portrait = portrait;
  }

  return supplierPhoto;
};

export default convertSupplierPhoto;
