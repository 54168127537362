import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconTiktok = ({ id, color, width = 12, style, ...restProps }: IProps) => {
  const viewBoxWidth = 12;
  const viewBoxHeight = 13;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        d="M11 4.92101C9.91871 4.92348 8.86393 4.60161 7.98468 4.00086V8.19067C7.98439 8.96667 7.7359 9.72408 7.27244 10.3616C6.80899 10.9992 6.15266 11.4865 5.39122 11.7584C4.62978 12.0303 3.79952 12.0738 3.01146 11.8832C2.22339 11.6925 1.5151 11.2768 0.981268 10.6915C0.447441 10.1062 0.11353 9.37936 0.0241855 8.60806C-0.0651593 7.83676 0.0943202 7.05781 0.481299 6.37536C0.868278 5.69291 1.46431 5.1395 2.1897 4.78911C2.91509 4.43873 3.73526 4.30808 4.54054 4.41463V6.52196C4.17204 6.41133 3.77634 6.41466 3.40995 6.53149C3.04355 6.64831 2.7252 6.87266 2.50035 7.17249C2.27551 7.47232 2.15566 7.83229 2.15794 8.20101C2.16021 8.56973 2.28449 8.92833 2.51302 9.22561C2.74155 9.52289 3.06265 9.74364 3.43046 9.85634C3.79827 9.96904 4.19399 9.96793 4.56109 9.85315C4.9282 9.73838 5.24793 9.51582 5.47461 9.21726C5.7013 8.91869 5.82335 8.55939 5.82334 8.19067V0H7.98468C7.98318 0.174218 7.99847 0.348198 8.03037 0.519741C8.10547 0.902685 8.26163 1.26698 8.4893 1.59034C8.71696 1.9137 9.01134 2.18933 9.35443 2.40037C9.84255 2.70844 10.4148 2.87264 11 2.87252V4.92101Z"
        fill={iconColor}
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconTiktok;
