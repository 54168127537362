import React from 'react';
import { A } from '@bridebook/ui';
import { Link } from 'app-shared/components/link/link';
import componentStyles from './track-link.style';

interface IProps {
  url: string;
  title: string;
}

const TrackLink = ({ url, title }: IProps) => {
  const styles = componentStyles();

  return (
    <Link href={`/${url}`} passHref legacyBehavior>
      <A color="space" style={styles.title}>
        {title}
      </A>
    </Link>
  );
};

export default TrackLink;
