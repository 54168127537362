import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconSupplierEntertainment = ({ id, color, width = 48, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.57597 6.74012C9.29899 5.69143 11.3391 5.29045 13.3308 5.60901C15.3226 5.92756 17.1358 6.94484 18.4458 8.47862C19.7558 10.0124 20.4769 11.9625 20.48 13.9795C20.4831 15.9966 19.7679 17.9489 18.4626 19.4866C17.1573 21.0244 15.3471 22.0472 13.3564 22.3718C11.3656 22.6964 9.32428 22.3016 7.59807 21.2582C5.87187 20.2148 4.57356 18.5908 3.9357 16.6773C3.29783 14.7637 3.3621 12.6856 4.11697 10.8151"
        stroke={iconColor ?? '#546170'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 4V7"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5.5H22"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6V9"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.5H6"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1V4"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 2.5H9.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7V21"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1108 20.5C16.2607 18.5268 16.8666 16.2838 16.8666 14C16.8666 11.7162 16.2607 9.47323 15.1108 7.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.87194 7.5C7.74276 9.48023 7.14893 11.7205 7.14893 14C7.14893 16.2795 7.74276 18.5198 8.87194 20.5"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00781 14.0081H19.0078"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5078 10.9C16.5346 9.75013 14.2916 9.14429 12.0078 9.14429C9.72399 9.14429 7.48104 9.75013 5.50781 10.9"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50781 17.137C7.48807 18.266 9.72829 18.8598 12.0078 18.8598C14.2873 18.8598 16.5276 18.266 18.5078 17.137"
        stroke={iconColor ?? '#1A2C40'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconSupplierEntertainment;
