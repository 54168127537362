import { mergeDeepRight } from 'ramda';
import React, { PropsWithChildren } from 'react';
import { IStylingProps, TSpacingValue } from '../../../themes/types';
import Box from '../../fela/Box';
import { FelaCSS } from '../../fela/flowtypes';
import componentStyle from './container.style';

interface IProps extends IStylingProps, PropsWithChildren {
  style?: FelaCSS;
  noPadding?: boolean;
  overridePadding?: TSpacingValue;
  fullWidth?: boolean;
  'data-name'?: string;
}

const Container = ({
  children,
  noPadding,
  overridePadding,
  style = {},
  fullWidth,
  'data-name': name,
  ...restProps
}: IProps) => {
  const styles = componentStyle({
    noPadding,
    overridePadding,
    fullWidth,
  });

  return (
    <Box style={mergeDeepRight(styles.wrap, style)} {...restProps} data-name={name || 'container'}>
      {children}
    </Box>
  );
};

export default Container;
