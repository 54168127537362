import { useEffect, useState } from 'react';

let isSupported: boolean | undefined = undefined;

function useNativeLazyLoading() {
  const [supported, setSupported] = useState<boolean | undefined>(isSupported);

  useEffect(() => {
    if (isSupported === undefined) {
      try {
        isSupported = 'loading' in HTMLImageElement.prototype;
      } catch (e) {
        isSupported = false;
      }
    }

    setSupported(isSupported);

    if (process.env.NODE_ENV === 'test') {
      isSupported = undefined;
    }
  }, []);

  return supported;
}

export default useNativeLazyLoading;
