import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconYoutube = ({ id, color, width = 50, style, ...restProps }: IProps) => {
  const viewBoxWidth = 50;
  const viewBoxHeight = 35;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M20.143 26.42l13.973-8.988-13.973-9.031v18.02zM26.392 35c-6.275-.121-11.462-.206-16.648-.333a37.194 37.194 0 0 1-3.936-.321c-1.71-.225-2.966-1.282-3.586-2.772-.74-1.774-1.33-3.663-1.629-5.558C-.2 20.965-.129 15.87.404 10.796c.202-1.921.61-3.826 1.03-5.715.164-.735.599-1.417.952-2.101C3.26 1.289 4.742.51 6.574.413 9.891.235 13.212.017 16.532.005 24.07-.02 31.61.05 39.149.127c1.657.016 3.314.201 4.967.35 1.584.142 2.703 1.046 3.509 2.347 1.41 2.278 1.887 4.853 2.062 7.452.221 3.277.326 6.568.312 9.852-.014 3.086-.29 6.169-1.166 9.155-.223.763-.546 1.505-.893 2.223-1.1 2.274-3.087 2.972-5.448 3.034-5.728.15-11.455.326-16.1.46z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconYoutube;
