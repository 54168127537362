import React from 'react';
import { IIconProps } from '../../types';
import SimpleIconWrap from './simple-icon-wrap';

const IconChevron = ({ id, color, width = 50, style, onClick }: IIconProps) => {
  const viewBoxWidth = 50;
  const viewBoxHeight = 27;

  return (
    <SimpleIconWrap
      onClick={onClick}
      id={id}
      viewBox={[0, 0, viewBoxWidth, viewBoxHeight]}
      width={width}
      style={style}
      color={color}
      path="M25 27a2.563 2.563 0 0 1-1.794-.729L.744 4.248a2.453 2.453 0 0 1 0-3.519 2.573 2.573 0 0 1 3.588 0l20.669 20.264L45.669.73a2.572 2.572 0 0 1 3.587 0 2.453 2.453 0 0 1 0 3.519L26.795 26.27a2.563 2.563 0 0 1-1.794.729"
    />
  );
};

export default IconChevron;
