import type { Market } from '@bridebook/toolbox/src/gazetteer';
import { imgixBaseURL } from 'lib/utils';

const APPSFLYER_ONELINK_BADGE_IOS = 'https://bridebook.onelink.me/q51D/badge';
const APPSFLYER_ONELINK_BADGE_ANDROID = 'https://bridebook.onelink.me/q51D/badgeandroid';
const QR_CODE_IMG_URL =
  'https://bridebook-imgs.s3.us-west-1.amazonaws.com/assets/appstore/app-download-qr-code.png';

type BadgesInfo = {
  apple: {
    img: string;
    url: string;
  };
  google: {
    img: string;
    url: string;
  };
  code: {
    img: string;
  };
};

// https://tools.applemediaservices.com/app/1200853011
const localeToAppleImg: Record<'en' | string, string> = {
  de: `${imgixBaseURL}/assets/appstore/de-badge-appstore.svg?auto=compress,format`,
  en: `${imgixBaseURL}/assets/appstore/appstore_v2.svg?auto=compress,format`,
  fr: `${imgixBaseURL}/assets/appstore/fr-badge-appstore.svg?auto=compress,format&v4`,
};

const getAppleImg = (lang: Market['language']) => localeToAppleImg[lang] || localeToAppleImg.en;

// https://play.google.com/intl/en_us/badges/
const langToGoogleImg: Record<'en' | string, string> = {
  de: `${imgixBaseURL}/assets/google-play/google-play-badge_de.png?auto=compress,format`,
  en: `${imgixBaseURL}/assets/google-play/google-play-badge_en.png?auto=compress,format`,
  fr: `${imgixBaseURL}/assets/google-play/google-play-badge_fr.png?auto=compress,format`,
};

const getGoogleImg = (lang: Market['language']) => langToGoogleImg[lang] || langToGoogleImg.en;

const getBadgesInfo = (market: Market): BadgesInfo => ({
  apple: {
    img: getAppleImg(market.language),
    url: APPSFLYER_ONELINK_BADGE_IOS,
  },
  google: {
    img: getGoogleImg(market.language),
    url: APPSFLYER_ONELINK_BADGE_ANDROID,
  },
  code: {
    img: QR_CODE_IMG_URL,
  },
});

export { getBadgesInfo };
