import React, { ReactNodeArray } from 'react';
import { Box } from '@bridebook/ui';
import componentStyles from './link-pipe-list.style';

interface IProps {
  list: ReactNodeArray;
}

const LinkPipeList = ({ list }: IProps) => {
  const styles = componentStyles();

  return (
    <Box style={styles.wrapper} data-name="link-pipe-list">
      {list.map((item, index) => (
        <Box
          style={styles.linkWrap}
          data-name="link-item"
          key={React.isValidElement(item) ? item.key : index}>
          {item}
        </Box>
      ))}
    </Box>
  );
};

export default LinkPipeList;
