import { addLocale } from 'next/dist/shared/lib/router/utils/add-locale';
import NextLink, { type LinkProps } from 'next/link';
import {
  type AnchorHTMLAttributes,
  type ReactNode,
  type RefAttributes,
  memo,
  useMemo,
} from 'react';
import { marketPrefixPattern } from '@bridebook/toolbox/src/gazetteer';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';

const LinkComponent = (
  // Props should be exactly the same as they are for NextLink component
  props: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: ReactNode;
    } & RefAttributes<HTMLAnchorElement>,
) => {
  const { as, href, ...rest } = props;
  /**
   * This is an exception from the rule to not use state-aware hooks in app shared. This is because
   * the component is heavy used and because we want it to pretend to be a Next.js Link component
   * (which obviously does not have `market` property).
   */
  const market = useMarket();
  const marketPrefix =
    href
      .toString()
      .match(new RegExp(`^[/](${marketPrefixPattern.source})(?:[/]|$)`))
      ?.pop() ?? market?.prefix;

  const asModified = useMemo(
    () => (as ? addLocale(as.toString(), marketPrefix) : undefined),
    [as, marketPrefix],
  );

  const hrefModified = useMemo(
    () => addLocale(href.toString(), marketPrefix),
    [href, marketPrefix],
  );

  return <NextLink {...rest} as={asModified} href={hrefModified} />;
};

export const Link = memo(LinkComponent);
